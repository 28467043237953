import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { map, Observable, Subscription, take } from "rxjs";
import { TesteService } from "src/app/shared/api-services/teste.service";
import {
  Questionario,
  parsePerguntasAdicionalToQuestionaro,
  parseRespostasToRespostasAdicional,
} from "src/app/shared/components/questionario-geral/PerguntaQuestionario.to";
import { QuestionarioGeralComponent } from "src/app/shared/components/questionario-geral/questionario-geral.component";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { LogService } from "src/app/shared/services/log.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { RespostaPerguntaCompInseguroV2TO } from "src/app/shared/tos/request-resposta-pergunta-comp-insguro.to";
import { ResultadoTesteTO } from "src/app/shared/tos/resultado-teste.to";

@Component({
  selector: "app-perguntas-comp-inseguro",
  templateUrl: "./perguntas-comp-inseguro.component.html",
  styleUrls: ["./perguntas-comp-inseguro.component.scss"],
})
export class PerguntasCompInseguroComponent implements OnInit, OnDestroy {
  @ViewChild(QuestionarioGeralComponent)
  popupQuestionario: QuestionarioGeralComponent;

  questionarios: Questionario[] = [];
  showQuestionario: boolean = false;
  loading: boolean = false;
  sub: Subscription;

  constructor(
    private sessionStorageService: SessionStorageService,
    private routerService: RouterService,
    private testeService: TesteService,
    private logService: LogService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.logService.append("Acessou Perguntas Comportamento Inseguro");
      const resultado = this.sessionStorageService.getItem<ResultadoTesteTO>(
        SessionStorageKeys.RESULTADO
      );
      if (!resultado.perguntasCompInseguro?.length) {
        this.logService.append(
          "Nao possui perguntas comportamento inseguro, encaminhando para o Epworth"
        );
        this.routerService.toPerguntasEpworth();
      } else {
        this.exibirComportamentoInseguro(resultado);
      }
    });
  }

  async exibirComportamentoInseguro(resultado: ResultadoTesteTO) {
    if (resultado.perguntasCompInseguro?.length) {
      this.showQuestionario = true;
      this.questionarios = parsePerguntasAdicionalToQuestionaro(
        resultado.perguntasCompInseguro
      );
      this.sub = this.onQuestionarioSuccess().subscribe((respostas) => {
        this.sessionStorageService.setItem(
          SessionStorageKeys.RESPOSTAS_COMPORTAMENTO_INSEGURO,
          respostas
        );
        this.routerService.toPerguntasEpworth();
      });
    }
  }

  private onQuestionarioSuccess(): Observable<
    RespostaPerguntaCompInseguroV2TO[]
  > {
    return this.popupQuestionario.onSuccess.pipe(
      map(parseRespostasToRespostasAdicional)
    );
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
