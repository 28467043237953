export enum SessionStorageKeys {
  CURRENT_URL = "current url",
  CONFIGURACAO = "configuracao",
  POSSUI_VARIAS_ETAPAS = "flVariasEtapas",
  CONFIGURACAO_TESTE = "configuracaoTeste",
  DESLOGAR_USUARIO = "deslogarUsuario",
  IMAGENS_INCORRETAS = "imagensIncorretas",
  IMAGENS_CORRETAS = "imagensCorretas",
  ALVOS = "alvos",
  PERGUNTAS = "perguntas",
  RESPOSTAS_COMPORTAMENTO_INSEGURO = "respostas_comportamento_inseguro",
  RESPOSTAS_SAM_PERELLI = "respostas_sam_perelli",
  RESPOSTAS_KAROLINSKA = "respostas_karolinska",
  RESULTADO = "resultado",
  PASS_IN_REDIRECT = "passRedirectPage",
  ID_EMOCIOGRAMA = "idEmociograma",
  RESPOSTA_BEM_ESTAR = "respostasBemEstar",
  ANALISE_GESTAO_OPERADOR = "analiseGestaoOperador",
  ENVIA_RETORNO_PARAM = "enviaRetornoParam",
  RESPOSTAS_PSICOLOGIA = "respostasPsicologia",
  RESPOSTA_EPWORTH = "respostaEpworth",
  RESPOSTA_MATUTINIDADE = "respostasMatutinidade",
  SME_DEVICE = "SME_DEVICE",
  SME_REDIRECT = "SME_REDIRECT",
  SME_TV = "SME_TV",
}
