import {
  JsonObject,
  JsonProperty,
  PropertyConvertingMode,
} from "json2typescript";
import { RespostaPerguntaCompInseguroV2TO } from "./request-resposta-pergunta-comp-insguro.to";
import { RespostaPerguntaAdicionalV2TO } from "./request-resposta-pergunta-adicional.to";
import { RequestPerguntaEpworh } from "./request-pergunta-epworth.to";

@JsonObject("RequestUploadQuestionarioV2TO")
export class RequestUploadQuestionarioV2TO {
  @JsonProperty("aa", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idTeste: number = null;

  @JsonProperty(
    "ab",
    [RespostaPerguntaCompInseguroV2TO],
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  respostasPerguntasCompInseguro: RespostaPerguntaCompInseguroV2TO[] = null;

  @JsonProperty("ac", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idUsuario: number = null;

  @JsonProperty(
    "ad",
    [RespostaPerguntaAdicionalV2TO],
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  respostasPerguntasSamPerelli: RespostaPerguntaAdicionalV2TO[] = null;

  @JsonProperty(
    "ae",
    [RespostaPerguntaAdicionalV2TO],
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  respostasPerguntasKarolinska: RespostaPerguntaAdicionalV2TO[] = null;

  @JsonProperty(
    "af",
    [RequestPerguntaEpworh],
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  respostaPerguntasEpworth: RequestPerguntaEpworh[] = null;
}
