export enum PageNames {
  INTRODUCAO = "introducao",
  PERGUNTAS = "perguntas",
  PERGUNTASINICIAL = "perguntasinicial",
  TESTE = "teste",
  RESULTADO = "resultado",
  DICA = "dica",
  CONFIGURACAO = "configuracao",
  REDIRECT = "redirect",
  NIVELPRONTIDAO = "nivel-prontidao",
  INICIO = "inicio",
  SAUDE_MENTAL = "saude-mental",
  FINALIZAR = "finalizar",
  EMOCIOGRAMA = "emociograma",
  EMOCIOGRAMA_INICIO = "inicio/emociograma",
  PROCESSAR_TESTE = "processar",
  SEGURANCA_PSICOLOGICA = "seguranca-psicologica",
  SEGURANCA_PSICOLOGICA_INICIO = "inicio/seguranca-psicologica",
  PERGUNTAS_EPWORTH = "perguntas-epworth",
  SIMULACAO_TESTE = "simulador/teste",
  SIMULACAO_INTRODUCAO = "simulador/introducao",
  PERGUNTAS_COMPORTAMENTO_INSEGURO = "perguntas-comp-inseguro",
  COMUNICADOS = "comunicados",
  PERGUNTAS_SAM_PERELLI = "perguntas-sam-perelli",
  PERGUNTAS_KAROLINSKA = "perguntas-karolinska",
  PERGUNTAS_MATUTINIDADE = "perguntas-matutinidade",
  DESEMPENHO = "desempenho",
  ABORDAGENS = "abordagens",
}
