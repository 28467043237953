import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  PerguntaQuestionario,
  Questionario,
} from "src/app/shared/components/questionario-geral/PerguntaQuestionario.to";
import { ResponderQuestionarioSegPsicologicaEnum } from "src/app/shared/enums/responder-questionario-seg-psicologica.enum";
import { SituacaoSegurancaPsicologicaEnum } from "src/app/shared/enums/situacao-seguranca-psicologica.enum";
import { TipoExibicaoPerguntaEnum } from "src/app/shared/enums/tipo-exibicao-pergunta.enum";
import { InactiveTimerService } from "src/app/shared/services/inactive-timer.service";
import { LogService } from "src/app/shared/services/log.service";
import { PerguntaSegurancaPsicologicaTO } from "src/app/shared/tos/pergunta-seguranca-psicologia.to";
import { RespostaSegurancaPsicologiaTO } from "src/app/shared/tos/resposta-seguranca-psicologia.to";

@Component({
  selector: "app-seguranca-psicologica",
  templateUrl: "./seguranca-psicologica.component.html",
  styleUrls: ["./seguranca-psicologica.component.scss"],
})
export class SegurancaPsicologicaComponent implements OnInit {
  @Output() onSubmit = new EventEmitter<RespostaSegurancaPsicologiaTO>();
  @Input() perguntas: PerguntaSegurancaPsicologicaTO[];
  @Input() flExibeSegurancaPsicologica: ResponderQuestionarioSegPsicologicaEnum;
  @Input() loading: boolean = false;
  exibirModalAdiamento: boolean = false;
  questionarios: Questionario[];
  submitted: boolean;
  isQuestionarioOpcional: boolean;

  constructor(
    private logService: LogService,
    private inactiveTimeService: InactiveTimerService
  ) {}

  ngOnInit(): void {
    this.logService.append(`Acessou seguranca psicologica`);
    this.inactiveTimeService.start(null, 60000 * 5);
    this.questionarios = this.parseToQuestionario(this.perguntas);
    this.exibirModalAdiamento = true;
    this.isQuestionarioOpcional =
      this.flExibeSegurancaPsicologica ===
      ResponderQuestionarioSegPsicologicaEnum.SIM_OPCIONAL;
  }

  parseToQuestionario(
    perguntas: PerguntaSegurancaPsicologicaTO[]
  ): Questionario[] {
    const questionarios: Questionario[] = [];
    const gruposPergunas: PerguntaSegurancaPsicologicaTO[][] = perguntas.reduce(
      (prev, cur) => {
        const indexAtual = cur.nrGrupo - 1 || 0;
        if (!prev[indexAtual]) {
          prev[indexAtual] = [];
        }
        prev[indexAtual].push(cur);
        return prev;
      },
      []
    );
    gruposPergunas.forEach((grupo) => {
      const perguntasQuestionario = [];
      grupo.forEach((pergunta) => {
        const perguntaQuestionario: PerguntaQuestionario = {
          id: pergunta.id,
          dsPergunta: pergunta.dsPergunta,
          tipo: TipoExibicaoPerguntaEnum.RADIO,
          obrigatorio: true,
          itens: pergunta.opcoes.map((opcao) => ({
            label: opcao.dsPergunta,
            value: opcao.idOpcao,
            nrOrdem: opcao.nrOrdem,
          })),
        };
        perguntasQuestionario.push(perguntaQuestionario);
      });
      questionarios.push({
        perguntas: perguntasQuestionario,
      });
    });
    return questionarios;
  }

  handleOpcaoSelecionada(situacao: SituacaoSegurancaPsicologicaEnum | string) {
    if (situacao !== SituacaoSegurancaPsicologicaEnum.RESPONDIDO) {
      const request = this.montarRequest(
        situacao as SituacaoSegurancaPsicologicaEnum
      );
      this.onSubmit.emit(request);
    }
  }

  montarRequest(
    situacao: SituacaoSegurancaPsicologicaEnum,
    respostas?: PerguntaQuestionario[]
  ) {
    const request = new RespostaSegurancaPsicologiaTO();
    request.stResposta = situacao;
    request.idOpcoesMarcadas = respostas?.map((r) => r.respostas);
    return request;
  }

  handleSubmit(respostas: PerguntaQuestionario[]) {
    this.submitted = true;
    const request = this.montarRequest(
      SituacaoSegurancaPsicologicaEnum.RESPONDIDO,
      respostas
    );
    this.onSubmit.emit(request);
  }
}
