// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { KeycloakOptions } from "keycloak-angular";

const getSubdomain = (subdomain) => {
  switch (subdomain) {
    case "terceirastu":
      return "mca";
    default:
      return subdomain;
  }
};

//Definir sudominio de trabalho
const hostname = location.hostname;
const posPrimeiroPonto = hostname.indexOf(".");
const _subdomain =
  hostname == "prontos.arcelormittal.com.br"
    ? "amt"
    : hostname.substring(0, posPrimeiroPonto);
const subdomain = getSubdomain(_subdomain);

//Recuperar paraemtro do request
var url = new URL(document.URL);
var embedded = new URLSearchParams(url.search).get("embedded") != null;

const keycloakInitOptions: KeycloakOptions = {
  config: {
    realm: subdomain,
    url: "https://hmg-keycloak.sistemaprontos.com.br/auth",
    clientId: "teste-web",
  },
  initOptions: {
    onLoad: "login-required",
    checkLoginIframe: false,
    silentCheckSsoRedirectUri:
      window.location.origin + "/assets/silent-check-sso.html",
  },
  loadUserProfileAtStartUp: false,
  bearerExcludedUrls: [],
};

export const environment = {
  dev: false,
  permitirPularEtapas: false,
  production: false,
  apiEndpointPath: `https://${subdomain}-api.sistemaprontos.com.br/teste-api/`,
  geralEndpointPath: `https://${subdomain}-api.sistemaprontos.com.br/`,
  keycloakInit: keycloakInitOptions,
  tempoEsperaFinalizacaoTeste: 180000, // 180000 = 3 minutos
  cliente: subdomain,
  flEmbedded: embedded,
  retryTime: 15,
  retryMax: 3,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
