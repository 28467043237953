import { TipoExibicaoPerguntaEnum } from "../../enums/tipo-exibicao-pergunta.enum";
import { PerguntaAdicionalV2TO } from "../../tos/pergunta-adicional-v2.to";
import { RespostaPerguntaAdicionalV2TO } from "../../tos/request-resposta-pergunta-adicional.to";

export interface ItemPerguntaQuestionario {
  value: any;
  label: string;
  nrOrdem?: number;
}

export interface PerguntaQuestionario {
  id: number;
  dsPergunta: string;
  obrigatorio: boolean;
  itens: ItemPerguntaQuestionario[];
  tipo: TipoExibicaoPerguntaEnum;
  respostas?: any;
  shouldDisplay?: (perguntas: Questionario[]) => boolean;
}

export interface Questionario {
  perguntas: PerguntaQuestionario[];
}

export const parsePerguntasAdicionalToQuestionaro = (
  perguntas: PerguntaAdicionalV2TO[]
): Questionario[] => {
  return perguntas.map((perguntaTO) => {
    const questionario = { perguntas: null };
    questionario.perguntas = [
      {
        dsPergunta: perguntaTO.dsPergunta,
        id: perguntaTO.id,
        tipo: perguntaTO.cdTipoExibicao,
        obrigatorio: true,
        nrOrdem: perguntaTO.nrOrdem,
        shouldDisplay: function (questionario: Questionario[]) {
          if (this.nrOrdem < 2) return true;
          const respostaSim = questionario[0].perguntas[0].itens.find(
            (item) => item.nrOrdem == 1
          );
          return questionario[0].perguntas[0].respostas == respostaSim.value;
        },
        itens: perguntaTO.opcoes.map(
          (opc) =>
            ({
              label: opc.dsOpcao,
              value: opc.id,
              nrOrdem: opc.nrOrdem,
            } as ItemPerguntaQuestionario)
        ),
      },
    ];
    return questionario;
  });
};

export const parseRespostasToRespostasAdicional = (
  perguntas: PerguntaQuestionario[]
): RespostaPerguntaAdicionalV2TO[] => {
  const respostasRequest: RespostaPerguntaAdicionalV2TO[] = perguntas.map(
    (p) => ({
      idPergunta: p.id,
      idOpcao: p.respostas,
    })
  );

  const respostasSpreaded = respostasRequest.reduce((prev, cur) => {
    if (!Array.isArray(cur.idOpcao)) {
      return [...prev, cur];
    }
    const spreaded = cur.idOpcao.map((idOpcao) => ({
      idPergunta: cur.idPergunta,
      idOpcao,
    }));
    return [...prev, ...spreaded];
  }, []);

  return respostasSpreaded;
};
