import { ImagemFinalEnum } from "../enums/ImagemFinal.enum";
import { HistoricoNormalEnum } from "../enums/historico-normal.enum";
import {
  JsonObject,
  JsonProperty,
  PropertyConvertingMode,
} from "json2typescript";
import { BooleanEnum } from "../enums/boolean.enum";
import { ResponseHistNivelResultadoTO } from "./response-hits-nivel-resultado.to";
import { PerguntaCompInseguroV2TO } from "./pergunta-comp-inseguro.to";
import { PerguntaAdicionalV2TO } from "./pergunta-adicional-v2.to";
import { ResponsePerguntaEpworthTO } from "./response-pergunta-epworth.to";

@JsonObject("ResultadoTesteTO")
export class ResultadoTesteTO {
  @JsonProperty("aa", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsMensagemRetorno: string = null;

  @JsonProperty("ab", String, PropertyConvertingMode.IGNORE_NULLABLE)
  cdImagemFinal: ImagemFinalEnum = null;

  @JsonProperty("ac", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  vlDesempenhoAtual: number = null;

  @JsonProperty("ad", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  vlDesempenhoAnterior: number = null;

  @JsonProperty("ae", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsDica: string = null;

  @JsonProperty("af", String, PropertyConvertingMode.IGNORE_NULLABLE)
  dsDicaFadiga: string = null;

  @JsonProperty("ag", String, PropertyConvertingMode.IGNORE_NULLABLE)
  cdEvoluiuRegrediu: HistoricoNormalEnum = null;

  @JsonProperty("ah", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idTesteAtual: number = null;

  @JsonProperty("ai", Number, PropertyConvertingMode.IGNORE_NULLABLE)
  idNivelResultado: number = null;

  @JsonProperty("aj", String, PropertyConvertingMode.IGNORE_NULLABLE)
  flUsuarioLiberado: BooleanEnum = null;

  @JsonProperty(
    "ak",
    [ResponseHistNivelResultadoTO],
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  histNivelResultadoValores: ResponseHistNivelResultadoTO[] = null;

  @JsonProperty(
    "al",
    [PerguntaCompInseguroV2TO],
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  perguntasCompInseguro: PerguntaCompInseguroV2TO[] = null;

  @JsonProperty("am", Boolean, PropertyConvertingMode.IGNORE_NULLABLE)
  flExibirSolicitarConversa: boolean = null;

  @JsonProperty(
    "an",
    [PerguntaAdicionalV2TO],
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  perguntasSamPerelli: PerguntaAdicionalV2TO[] = null;

  @JsonProperty(
    "ao",
    [PerguntaAdicionalV2TO],
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  perguntasKarolinska: PerguntaAdicionalV2TO[] = null;

  @JsonProperty(
    "ap",
    [ResponsePerguntaEpworthTO],
    PropertyConvertingMode.IGNORE_NULLABLE
  )
  perguntasEpworth: ResponsePerguntaEpworthTO[] = null;

  exibirDesempenho: boolean = false;
}
