import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import Dexie from "dexie";
import { BehaviorSubject } from "rxjs";
import { dispatchError } from "src/app/core/dispatchError";
import { dispatchEvent } from "src/app/core/dispatchEvent";
import { StringUtils } from "../utils/StringUtils";

interface ImagemCached {
  [url: string]: SafeUrl;
}

interface ImagemDB {
  blob: ArrayBuffer;
  url: string;
}

@Injectable({
  providedIn: "root",
})
export class ImagemCacheService {
  private canUseIndexedDb = true;
  private imagensSafeUrl: ImagemCached = {};
  private db: any;
  private cacheInitialUrls: string[] = [
    "assets/images/logo.png",
    "assets/images/teste/testeA/seta_direita_esquerda.png",
    "assets/images/teste/testeA/seta_esquerda.png",
    "assets/images/teste/testeA/seta_direita.png",
    "assets/images/teste/testeB/seta_redonda_direita.png",
    "assets/images/teste/testeB/seta_redonda_direita_cima.png",
    "assets/images/teste/testeB/seta_redonda_esquerda_baixo.png",
    "assets/images/teste/testeB/seta_redonda_esquerda_cima.png",
    "assets/images/teste/!01.png",
    "assets/images/teste/!02.png",
    "assets/images/teste/!05.png",
    "assets/images/teste/!06.png",
    "assets/images/teste/!p01.png",
    "assets/images/teste/!p02.png",
    "assets/images/teste/!p05.png",
    "assets/images/teste/resultado/atencao.png",
    "assets/images/teste/resultado/liberado.png",
    "assets/images/teste/resultado/supervisor.png",
    "assets/images/emociograma/agradecido.png",
    "assets/images/emociograma/cansado_sme.png",
    "assets/images/emociograma/estressado.png",
    "assets/images/emociograma/muito_feliz.png",
    "assets/images/emociograma/muito_triste_sme.png",
    "assets/images/emociograma/neutro_sme.png",
    "assets/images/emociograma/triste.png",
    "assets/images/emociograma/ansioso.png",
    "assets/images/emociograma/com_sono.png",
    "assets/images/emociograma/feliz.png",
    "assets/images/emociograma/muito_feliz_sme.png",
    "assets/images/emociograma/nervoso.png",
    "assets/images/emociograma/preocupado.png",
    "assets/images/emociograma/triste_sme.png",
    "assets/images/emociograma/bem.png",
    "assets/images/emociograma/doente.png",
    "assets/images/emociograma/feliz_sme.png",
    "assets/images/emociograma/muito_triste.png",
    "assets/images/emociograma/neutro.png",
    "assets/images/emociograma/relaxado.png",
    "assets/images/bem-estar/bacteria.png",
    "assets/images/bem-estar/cough.png",
    "assets/images/bem-estar/gerdau_confirmacao.png",
    "assets/images/bem-estar/gerdau_mais_cuidado_qrcode.jpg",
    "assets/images/bem-estar/snowflake.png",
    "assets/images/bem-estar/temperature.png",
    "assets/images/bem-estar/bacteria2.png",
    "assets/images/bem-estar/flower.png",
    "assets/images/bem-estar/gerdau_mais_cuidado.jpg",
    "assets/images/bem-estar/lungs.png",
    "assets/images/bem-estar/stomach.png",
    "assets/images/bem-estar-ilustrado/bebida_alcoolica.png",
    "assets/images/bem-estar-ilustrado/dificuldade_familiar.png",
    "assets/images/bem-estar-ilustrado/dificuldade_trabalho.png",
    "assets/images/bem-estar-ilustrado/indisposto.png",
    "assets/images/bem-estar-ilustrado/sonolento.png",
    "assets/images/bem-estar-ilustrado/dificuldade_concentrar.png",
    "assets/images/bem-estar-ilustrado/dificuldade_financeira.png",
    "assets/images/bem-estar-ilustrado/dor.png",
    "assets/images/bem-estar-ilustrado/medicamento_controlado.png",
    "assets/images/bem-estar-ilustrado/tristeza.png",
  ];

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  async init(deletarBanco = false) {
    try {
      dispatchEvent("IMAGE_CACHE", { acao: "INIT", deletarBanco });
      if (deletarBanco) {
        this.db = null;
        this.imagensSafeUrl = {};
        await Dexie.delete("ProntosDB");
        dispatchEvent("IMAGE_CACHE", { acao: "INIT_DELETE_DB", deletarBanco });
      }
      await this.criarDB();
      if (this.db) {
        await this.db?.open();
        await this.restoreImagesFromDatabase();
        await this.cacheImagensIniciais();
      }
    } catch (err) {
      dispatchEvent("IMAGE_CACHE", { acao: "INIT_ERROR" });
      if (err?.message?.indexOf("mutation") >= 0) {
        dispatchError("ERRO_CARREGAR_IMAGENS_MUTATION", err);
        this.canUseIndexedDb = false;
      } else {
        dispatchError("ERRO_CARREGAR_IMAGENS", err);
      }
    }
  }

  async verificarImagensCacheCarregadas(tentativas = 0) {
    dispatchEvent("IMAGE_CACHE", { acao: "VERIFY_LOAD_IMAGES" });
    if (!this.canUseIndexedDb) {
      return true;
    }
    await this.db?.open();
    const isImagensCacheCarregadas = await this.isTodasImagensCarregadas();
    if (isImagensCacheCarregadas) {
      dispatchEvent("IMAGE_CACHE", { acao: "VERIFY_LOAD_IMAGES_SUCCESS" });
      return true;
    }
    if (tentativas == 0) {
      const deletarBanco = true;
      await this.init(deletarBanco);
      dispatchEvent("IMAGE_CACHE", { acao: "VERIFY_LOAD_IMAGES_TRY_AGAIN" });
      return await this.verificarImagensCacheCarregadas(tentativas + 1);
    } else {
      dispatchEvent("IMAGE_CACHE", { acao: "VERIFY_LOAD_IMAGES_ERROR" });
      return false;
    }
  }

  private async isTodasImagensCarregadas() {
    const imagensSalvas = (await this.db.images.toArray()) as ImagemDB[];
    const imagensNaoSalvas = this.cacheInitialUrls.filter((imageToCache) => {
      return (
        imagensSalvas.find((imageCached) => imageCached.url == imageToCache) ==
        undefined
      );
    });
    dispatchEvent("IMAGE_CACHE", {
      acao: "IS_ALL_IMAGES_LOADED",
      imagensNaoSalvas: imagensNaoSalvas.length,
    });
    return imagensNaoSalvas.length == 0;
  }

  private async criarDB() {
    this.db = new Dexie("ProntosDB", { autoOpen: true });
    await this.db
      .version(3)
      .stores({
        images: "&url",
      })
      .upgrade((t) => {
        t.images.clear();
      });
    dispatchEvent("IMAGE_CACHE", { acao: "DB_CREATED" });
  }

  private normalizeUrl(url: string) {
    const hasInitalHash = url.charAt(0) == "/";
    if (hasInitalHash) {
      return url.substring(1);
    }
    return url;
  }

  public getImagem(url: string) {
    const urlNormalized = this.normalizeUrl(url);
    if (this.imagensSafeUrl[urlNormalized]) {
      return this.imagensSafeUrl[urlNormalized];
    }
    if (this.db?.isOpen()) {
      this.salvarImagem(url).then(() => {});
    }
    return url;
  }

  private async cacheImagensIniciais() {
    const imagensSalvas = await this.restoreImagesFromDatabase();
    const imagensToSave = this.cacheInitialUrls.filter(
      (url) => !imagensSalvas[url]
    );
    const requests = imagensToSave.map((url) =>
      this.salvarImagem.call(this, url)
    );
    await Promise.all(requests);
    await this.restoreImagesFromDatabase();
    dispatchEvent("IMAGE_CACHE", {
      acao: "IMG_INITIAL",
      quantidade: imagensToSave?.length,
    });
  }

  private async restoreImagesFromDatabase(): Promise<ImagemCached> {
    if (this.db?.isOpen()) {
      const imagensSalvas = (await this.db.images.toArray()) as ImagemDB[];
      imagensSalvas.forEach((img) => {
        this.saveImageLocal(img.blob, img.url);
      });
      dispatchEvent("IMAGE_CACHE", {
        acao: "RESTORE_FROM_DB",
        quantidade: this.imagensSafeUrl
          ? Object.keys(this.imagensSafeUrl).length
          : 0,
      });
      return this.imagensSafeUrl;
    }
    return {};
  }

  private saveImageLocal(blob, url) {
    const base64 = StringUtils.arrayBufferToBase64(blob);
    this.imagensSafeUrl[url] = this.sanitizer.bypassSecurityTrustUrl(
      "data:image;base64," + base64
    );
  }

  private async salvarImagem(url: string) {
    try {
      const res = await this.http
        .get("{external}" + url, { responseType: "arraybuffer" })
        .toPromise();
      this.saveImageLocal(res, this.normalizeUrl(url));
      await this.db.images.add({ blob: res, url: this.normalizeUrl(url) });
    } catch (err) {}
  }
}
